@import 'custom.scss';

.bg-cookie-detail, 
.bg-cookie-banner {
    background-color: $black;
}

.toast-container {
    position: fixed !important;
}

.navbar {
    font-size: 1rem;
    button, .btn {
        font-size: 1rem;
    }
}

.image-zoom {
    transition: transform .2s; /* Animation */
}
.image-zoom:hover {
    transform: scale(1.5); /* (110% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
}

.app-footer {
    background-image: url('../img/footer-bg.webp');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: bottom;
}

h2, .h2 {
    font-size: 60px;
    font-weight: 800;
}

h3, .h3 {
    font-weight: 800;
}

.component-heading {
    margin: 10% 3%;
    position: relative;
    color: $white;
    font-size: min(60px, 7vw);
    font-weight: 800;
}

.component-heading {
    h2, .h2 {
        font-size: inherit;
        font-weight: inherit;
    }
    &:after {
        content: attr(title);
        position: absolute;
        z-index: -1;
        top: -7%;
        left: -3%;
        bottom: 0;
        width: 100%;
        height: 100%;
        white-space: nowrap;
        display: flex;
        align-items: center;
        justify-content: left;
        font-size: 180%;
        line-height: 1;
        color: $black;
        text-shadow: -1px 0 rgba(255, 255, 255, 0.1), 0 1px rgba(255, 255, 255, 0.1), 1px 0 rgba(255, 255, 255, 0.1), 0 -1px rgba(255, 255, 255, 0.1);
    }
}

.app-footer-social-buttons a {
    padding: .2rem;
}

.variant-selector select {
    text-align: center;
}

:not(.form-switch) > .form-check-input:checked {
    background-color: $gray !important;
    border-color: $light !important;
}

@include media-breakpoint-up(md) { 
    .about {
        .caption {
            font-size: 1.5rem;
            line-height: 1.4;
            padding: 3rem 5rem !important;
        }
    }
}

.carousel-caption {
    .line-2 {
        font-weight: 800;
        line-height: .9;
    }
}
@include media-breakpoint-up(md) { 
    .carousel-caption {
        font-size: 3rem;
    }
}

.photos {
    a:link, a:visited, a:hover, a:active {
        text-decoration: none;
    }
    .card-body {
        text-align: center;
    }
}

#main-navbar svg {
    display: block;
    margin-top: 0.2em;
}

label {
    font-size: 1rem;
}

button.transparent,
button.transparent:focus,
button.transparent:active,
button.transparent:hover {
    background-color: transparent;
    background-repeat: no-repeat;
    border: none;
    cursor: pointer;
    overflow: hidden;
    outline: none;
}

.form-control:focus,
.form-check-input:focus {
    border-color: $black;
    box-shadow: 0 0 0 0.25rem $black;
}

:not(.form-switch)>.form-check-input:checked {
    background-color: #fff;
    border-color: $black;
}

.form-switch .form-check-input {
    border-radius: 0;
}

.form-switch>.form-check-input:checked {
    background-color: $black;
    border-color: $black;
}

.login-form,
.pwd-reset-form,
.register-form,
.profile-form,
.pwd-change-form {
    max-width: 500px;
}


button.simple-icon {
    border: none;
    background: transparent;
}

.MyContent {
    .btn {
        font-size: inherit;
    }
}

.loader {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 6rem;
    margin-top: 3rem;
    margin-bottom: 3rem;
}

.loader:before,
.loader:after {
    content: "";
    position: absolute;
    border-radius: 50%;
    animation: pulsOut 1.8s ease-in-out infinite;
    filter: drop-shadow(0 0 1rem rgba(255, 255, 255, 0.75));
}

.loader:before {
    width: 100%;
    padding-bottom: 100%;
    box-shadow: inset 0 0 0 1rem #fff;
    animation-name: pulsIn;
}

.loader:after {
    width: calc(100% - 2rem);
    padding-bottom: calc(100% - 2rem);
    box-shadow: 0 0 0 0 #fff;
}

@keyframes pulsIn {
    0% {
        box-shadow: inset 0 0 0 1rem #fff;
        opacity: 1;
    }

    50%,
    100% {
        box-shadow: inset 0 0 0 0 #fff;
        opacity: 0;
    }
}

@keyframes pulsOut {

    0%,
    50% {
        box-shadow: 0 0 0 0 #fff;
        opacity: 0;
    }

    100% {
        box-shadow: 0 0 0 1rem #fff;
        opacity: 1;
    }
}

.mysubs-grid { 
    display: grid;
    grid-template-columns: max-content auto max-content max-content;
    gap: .3rem;
}

.mysubs-grid > div {
    padding: 0 .1rem;
}

.mysubs-grid .line {
    grid-column: 1 / -1;
    height: 2px;
    border-bottom: 1px solid black;
    width: 100%;
}